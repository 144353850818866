import React from 'react';
import Navbar from '../Navbar';
import './index.css'

const EngineeringTeam = () => {
  const teamMembers = [
    { name: 'Shreyas Patil', role: 'Software Developer Intern', photo: 'https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg', linkedin: 'https://www.linkedin.com/in/shreyas-patil-sp002', }, 
    { name: 'Sparsh Rastogi', role: 'Full Stack Developer Intern', photo: 'https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg', linkedin: 'https://www.linkedin.com/in/sparsh-rastogi-502313203'},
  ];
  return (
      <> 
      <Navbar/>
      <div className='cont-team'>
        <div className="team-container">
      <h2>Meet Our Team</h2>
      <p className='team-sub'>Our talented engineering team is dedicated to building innovative solutions, driving technological advancements, and shaping the future of the real estate and legal industries.</p>
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <img src={member.photo} alt={member.name} className="team-member-photo" /> 
            <h3>{member.name}</h3>
            <p>{member.role}</p>
            <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://img.freepik.com/premium-vector/linkedin-app-icon-world-s-largest-professional-network-social-networking-jobs-careers_277909-476.jpg"
                    alt="LinkedIn"
                    id='social-img-team'
                    className='linkedin-icon'
                  />
            </a>
          </div>
        ))}
      </div>
        </div> 
      </div>
      </>
  );
};

export default EngineeringTeam;

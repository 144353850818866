const properties = {
  Mumbai: [
    {'id': 1,
      'propertyName': 'Evening Glory',
      'description': '1 Bedroom 570 Sq.Ft. Apartment in Chandivali Mumbai',
      'price': '₹ 1.02 Cr.',
      'City': 'Mumbai',
      'Locality': 'Chandivali',
      'Price': '1.02 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638450821417292315-030324050221221.jpg?aio=w-545;h-419;crop;'},
     {'id': 2,
      'propertyName': 'Silver Shree Swami Samarth Nagar',
      'description': '1 Bedroom 505 Sq.Ft. Apartment in Virar East Mumbai',
      'price': '₹ 21.85 L',
      'City': 'Mumbai',
      'Locality': 'Virar East',
      'Price': '21.85 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638422161602292112-300124125600560.jpg?aio=w-745;h-419;crop;'},
     {'id': 3,
      'propertyName': 'New Projects Puri Velvetlife ',
      'description': '4 Bedroom 1621 Sq.Ft. Apartment in Andheri East Mumbai',
      'price': '₹ 5.1 Cr.',
      'City': 'Mumbai',
      'Locality': 'Andheri East',
      'Price': '5.1 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638414767955835576-2101241133153315.jpg?aio=w-745;h-419;crop;'},
     {'id': 4,
      'propertyName': 'Lotus Signature',
      'description': 'Commercial Office Space 2545 Sq.Ft. in Andheri West Mumbai',
      'price': '₹ 11.75 Cr.',
      'City': 'Mumbai',
      'Locality': 'Andheri West',
      'Price': '11.75 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638448128396345090-2902240213591359.jpg?aio=w-745;h-419;crop;'},
     {'id': 5,
      'propertyName': 'Shikara Heights',
      'description': '2 Bedroom 522 Sq.Ft. Apartment in Sion Mumbai',
      'price': '₹ 1.84 Cr.',
      'City': 'Mumbai',
      'Locality': 'Sion',
      'Price': '1.84 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638478248378964687-0404241053575357.jpeg?aio=w-745;h-419;crop;'},
     {'id': 6,
      'propertyName': 'Vaibhav Laxmi Khernagar Bandra East ',
      'description': '2 Bedroom 618 Sq.Ft. Apartment in Bandra East Mumbai',
      'price': '₹ 2.4 Cr.',
      'City': 'Mumbai',
      'Locality': 'Bandra East',
      'Price': '2.4 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638389673933288480-2312231029532953.jpg?aio=w-745;h-419;crop;'},
     {'id': 7,
      'propertyName': 'Gurukrupa Marina Enclave',
      'description': '2 Bedroom 650 Sq.Ft. Apartment in Malad West Mumbai',
      'price': '₹ 1.42 Cr.',
      'City': 'Mumbai',
      'Locality': 'Malad West',
      'Price': '1.42 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638477363287417676-0304241018481848.jpg?aio=w-745;h-419;crop;'},
     {'id': 8,
      'propertyName': 'Vrindavan Height',
      'description': '2 Bedroom 1400 Sq.Ft. Apartment in Vasai East Mumbai',
      'price': '₹ 8 L',
      'City': 'Mumbai',
      'Locality': 'Vasai East',
      'Price': '8 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638474086603844219-3003240317401740.jpg?aio=w-745;h-419;crop;'},
     {'id': 9,
      'propertyName': 'Adiraj Status',
      'description': '1 Bedroom 412 Sq.Ft. Apartment in Nalasopara West Mumbai',
      'price': '₹ 36 L',
      'City': 'Mumbai',
      'Locality': 'Nalasopara West',
      'Price': '36 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638506102203017595-060524043700370.jpg?aio=w-745;h-419;crop;'},
     {'id': 10,
      'propertyName': 'CCI Rivali Park',
      'description': '2 Bedroom 814 Sq.Ft. Apartment in Borivali East Mumbai',
      'price': '₹ 2.6 Cr.',
      'City': 'Mumbai',
      'Locality': 'Borivali East',
      'Price': '2.6 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638438711798389491-1802240439393939.png?aio=w-745;h-419;crop;'},
     {'id': 11,
      'propertyName': 'Shah Arcade II',
      'description': '2 Bedroom 700 Sq.Ft. Apartment in Malad East Mumbai',
      'price': '₹ 1.55 Cr.',
      'City': 'Mumbai',
      'Locality': 'Malad East',
      'Price': '1.55 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638465578696809862-2003240657495749.jpg?aio=w-745;h-419;crop;'},
     {'id': 12,
      'propertyName': 'Kanakia Silicon Valley',
      'description': '3 Bedroom 986 Sq.Ft. Apartment in Powai Mumbai',
      'price': '₹ 3.35 Cr.',
      'City': 'Mumbai',
      'Locality': 'Powai',
      'Price': '3.35 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638451701022092610-0403240528222822.jpg?aio=w-745;h-419;crop;'},
     {'id': 13,
      'propertyName': 'Lodha Evoq',
      'description': '4 Bedroom 2700 Sq.Ft. Apartment in Wadala Mumbai',
      'price': '₹ 8.89 Cr.',
      'City': 'Mumbai',
      'Locality': 'Wadala',
      'Price': '8.89 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638453293214006496-060324014201421.jpeg?aio=w-745;h-419;crop;'},
     {'id': 14,
      'propertyName': 'Gurukrupa Marina Enclave',
      'description': '2 Bedroom 634 Sq.Ft. Apartment in Malad West Mumbai',
      'price': '₹ 1.46 Cr.',
      'City': 'Mumbai',
      'Locality': 'Malad West',
      'Price': '1.46 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638481095172971013-0704240558375837.jpg?aio=w-745;h-419;crop;'},
     {'id': 15,
      'propertyName': 'Shapoorji Pallonji Virar Palm Grove',
      'description': '2 Bedroom 644 Sq.Ft. Apartment in Virar West Mumbai',
      'price': '₹ 70 L',
      'City': 'Mumbai',
      'Locality': 'Virar West',
      'Price': '70 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638391049369003555-2512231242164216.jpg?aio=w-745;h-419;crop;'},
     {'id': 16,
      'propertyName': 'Raj Viva Maitry Heights',
      'description': '1 Bedroom 585 Sq.Ft. Apartment in Virar West Mumbai',
      'price': '₹ 27 L',
      'City': 'Mumbai',
      'Locality': 'Virar West',
      'Price': '27 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638398689954134880-0301240856355635.jpg?aio=w-745;h-419;crop;'},
     {'id': 17,
      'propertyName': 'Kanakia Silicon Valley',
      'description': '4 Bedroom 1550 Sq.Ft. Apartment in Powai Mumbai',
      'price': '₹ 4.65 Cr.',
      'City': 'Mumbai',
      'Locality': 'Powai',
      'Price': '4.65 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638399646179210966-0401241130173017.jpeg?aio=w-745;h-419;crop;'},
     {'id': 18,
      'propertyName': 'Chauhan Chamunda Classic',
      'description': '2 Bedroom 1250 Sq.Ft. Apartment in Dahisar East Mumbai',
      'price': '₹ 1.22 Cr.',
      'City': 'Mumbai',
      'Locality': 'Dahisar East',
      'Price': '1.22 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638398101942479042-0201240436343634.jpeg?aio=w-745;h-419;crop;'},
     {'id': 19,
      'propertyName': 'Hiranandani Avalon',
      'description': '3 Bedroom 1490 Sq.Ft. Apartment in Powai Mumbai',
      'price': '₹ 5.5 Cr.',
      'City': 'Mumbai',
      'Locality': 'Powai',
      'Price': '5.5 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638393617405839217-281223120220220.jpg?aio=w-745;h-419;crop;'},
     {'id': 20,
      'propertyName': 'Origin Claremont',
      'description': '2 Bedroom 840 Sq.Ft. Apartment in Goregaon East Mumbai',
      'price': '₹ 1.84 Cr.',
      'City': 'Mumbai',
      'Locality': 'Goregaon East',
      'Price': '1.84 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638517156957208001-1905241141354135.jpg?aio=w-745;h-419;crop;'},
     {'id': 21,
      'propertyName': 'Nexa Goyal Aspire',
      'description': '2 Bedroom 584 Sq.Ft. Builder Floor in Kandivali West Mumbai',
      'price': '₹ 1.73 Cr.',
      'City': 'Mumbai',
      'Locality': 'Kandivali West',
      'Price': '1.73 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638527779558503204-3105240645554555.jpg?aio=w-745;h-419;crop;'},
     {'id': 22,
      'propertyName': 'Integrated Kavya',
      'description': 'Commercial Office Space 498 Sq.Ft. in Andheri West Mumbai',
      'price': '₹ 1.56 Cr.',
      'City': 'Mumbai',
      'Locality': 'Andheri West',
      'Price': '1.56 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638491420884709781-190424044808488.jpeg?aio=w-745;h-419;crop;'},
     {'id': 23,
      'propertyName': 'Pegasus Apartment',
      'description': '4 Bedroom 1650 Sq.Ft. Apartment in Santacruz West Mumbai',
      'price': '₹ 8.5 Cr.',
      'City': 'Mumbai',
      'Locality': 'Santacruz West',
      'Price': '8.5 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638466645932017278-2203241236333633.jpg?aio=w-745;h-419;crop;'},
     {'id': 24,
      'propertyName': 'Prathamesh Ashish',
      'description': '2 Bedroom 1150 Sq.Ft. Apartment in Mira Road Mumbai',
      'price': '₹ 92 L',
      'City': 'Mumbai',
      'Locality': 'Mira Road',
      'Price': '92 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638493929452492704-220424022905295.jpg?aio=w-745;h-419;crop;'},
     {'id': 25,
      'propertyName': 'Hiranandani Atlantis',
      'description': '2 Bedroom 771 Sq.Ft. Apartment in Powai Mumbai',
      'price': '₹ 2.93 Cr.',
      'City': 'Mumbai',
      'Locality': 'Powai',
      'Price': '2.93 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638405233347094867-1001241042144214.jpg?aio=w-745;h-419;crop;'},
     {'id': 26,
      'propertyName': 'Rock Highland ',
      'description': '2 Bedroom 745 Sq.Ft. Builder Floor in Kandivali West Mumbai',
      'price': '₹ 1.39 Cr.',
      'City': 'Mumbai',
      'Locality': 'Kandivali West',
      'Price': '1.39 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638404030550932840-0901240117351735.jpg?aio=w-745;h-419;crop;'},
     {'id': 27,
      'propertyName': 'Omkar Alta Monte',
      'description': '2 Bedroom 1190 Sq.Ft. Apartment in Malad East Mumbai',
      'price': '₹ 2.74 Cr.',
      'City': 'Mumbai',
      'Locality': 'Malad East',
      'Price': '2.74 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638531104360785582-040624030716716.jpg?aio=w-745;h-419;crop;'},
     {'id': 28,
      'propertyName': 'Shapoorji Pallonji The Designate',
      'description': '3 Bedroom 1156 Sq.Ft. Apartment in Khar West Mumbai',
      'price': '₹ 6.5 Cr.',
      'City': 'Mumbai',
      'Locality': 'Khar West',
      'Price': '6.5 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638413823246488730-2001240918441844.jpg?aio=w-745;h-419;crop;'},
     {'id': 29,
      'propertyName': 'Arihant Residency Sion',
      'description': '1 Bedroom 428 Sq.Ft. Apartment in Sion Mumbai',
      'price': '₹ 1.25 Cr.',
      'City': 'Mumbai',
      'Locality': 'Sion',
      'Price': '1.25 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638533708733514137-0706240327532753.jpeg?aio=w-745;h-419;crop;'},
     {'id': 30,
      'propertyName': 'Unique Shanti The Skyline',
      'description': '2 Bedroom 675 Sq.Ft. Apartment in Mira Road Mumbai',
      'price': '₹ 1.18 Cr.',
      'City': 'Mumbai',
      'Locality': 'Mira Road',
      'Price': '1.18 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638466351357255593-2103240425352535.jpg?aio=w-745;h-419;crop;'},
     {'id': 31,
      'propertyName': 'Lake Florence',
      'description': '2 Bedroom 985 Sq.Ft. Apartment in Powai Mumbai',
      'price': '₹ 2.15 Cr.',
      'City': 'Mumbai',
      'Locality': 'Powai',
      'Price': '2.15 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638416830927586469-2401240851325132.jpg?aio=w-745;h-419;crop;'},
     {'id': 32,
      'propertyName': 'Continental Bandra',
      'description': '3 Bedroom 1650 Sq.Ft. Apartment in Bandra West Mumbai',
      'price': '₹ 7 Cr.',
      'City': 'Mumbai',
      'Locality': 'Bandra West',
      'Price': '7 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638393827735906175-2812230552535253.jpg?aio=w-745;h-419;crop;'},
     {'id': 33,
      'propertyName': 'Ashoka Apartment ',
      'description': '3 Bedroom 1400 Sq.Ft. Apartment in Vasai West Mumbai',
      'price': '₹ 1.3 Cr.',
      'City': 'Mumbai',
      'Locality': 'Vasai West',
      'Price': '1.3 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638475850328949302-0104240417121712.jpg?aio=w-745;h-419;crop;'},
     {'id': 34,
      'propertyName': 'Tanvi Eminence',
      'description': '3 Bedroom 1250 Sq.Ft. Apartment in Mira Road Mumbai',
      'price': '₹ 1.65 Cr.',
      'City': 'Mumbai',
      'Locality': 'Mira Road',
      'Price': '1.65 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638425941237264474-0302240955235523.jpg?aio=w-745;h-419;crop;'},
     {'id': 35,
      'propertyName': 'Dlh Swanlake Andheri West',
      'description': '3 Bedroom 1100 Sq.Ft. Apartment in Andheri West Mumbai',
      'price': '₹ 2.8 Cr.',
      'City': 'Mumbai',
      'Locality': 'Andheri West',
      'Price': '2.8 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638467975740696564-2303240132543254.jpg?aio=w-745;h-419;crop;'},
     {'id': 36,
      'propertyName': 'Hira Keshav CHS',
      'description': '1 Bedroom 298 Sq.Ft. Apartment in Ghatkopar West Mumbai',
      'price': '₹ 52 L',
      'City': 'Mumbai',
      'Locality': 'Ghatkopar West',
      'Price': '52 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638426916159523863-050224010015015.jpg?aio=w-745;h-419;crop;'},
     {'id': 37,
      'propertyName': 'Vaswani Bel Air 36th Rd, Off Linking Road, Khar, Bandra West, Mumbai,',
      'description': '2 Bedroom 800 Sq.Ft. Apartment in Bandra West Mumbai',
      'price': '₹ 4.75 Cr.',
      'City': 'Mumbai',
      'Locality': 'Bandra West',
      'Price': '4.75 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638507639492752166-080524111909199.jpg?aio=w-745;h-419;crop;'},
     {'id': 38,
      'propertyName': 'Kings My Homes',
      'description': '1 Bedroom 322 Sq.Ft. Apartment in Chunnabhatti Mumbai',
      'price': '₹ 77.00 L',
      'City': 'Mumbai',
      'Locality': 'Chunnabhatti',
      'Price': '77.00 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638512981329675658-1405240342124212.jpg?aio=w-745;h-419;crop;'},
     {'id': 39,
      'propertyName': 'Lokhandwala Whispering Palms',
      'description': '3 Bedroom 865 Sq.Ft. Apartment in Kandivali East Mumbai',
      'price': '₹ 2.2 Cr.',
      'City': 'Mumbai',
      'Locality': 'Kandivali East',
      'Price': '2.2 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638423053426106115-3101240142224222.jpg?aio=w-745;h-419;crop;'},
     {'id': 40,
      'propertyName': 'Agarwal Sky Heights',
      'description': '2 Bedroom 615 Sq.Ft. Apartment in Vasai East Mumbai',
      'price': '₹ 65.76 L',
      'City': 'Mumbai',
      'Locality': 'Vasai East',
      'Price': '65.76 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638499845390653718-2904241048594859.jpg?aio=w-745;h-419;crop;'},
     {'id': 41,
      'propertyName': 'Shapoorji Pallonji Alpine',
      'description': '3 Bedroom 1047 Sq.Ft. Apartment in Kandivali East Mumbai',
      'price': '₹ 3.35 Cr.',
      'City': 'Mumbai',
      'Locality': 'Kandivali East',
      'Price': '3.35 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638497007294095103-2604240358495849.jpg?aio=w-745;h-419;crop;'},
     {'id': 42,
      'propertyName': 'Deep Heights Nalasopara',
      'description': '1 Bedroom 600 Sq.Ft. Apartment in Nalasopara West Mumbai',
      'price': '₹ 29 L',
      'City': 'Mumbai',
      'Locality': 'Nalasopara West',
      'Price': '29 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638461846117188932-1603241116511651.jpg?aio=w-745;h-419;crop;'},
     {'id': 43,
      'propertyName': 'Charkop Shreeji CHS',
      'description': '1 Bedroom 466 Sq.Ft. Apartment in Kandivali West Mumbai',
      'price': '₹ 92 L',
      'City': 'Mumbai',
      'Locality': 'Kandivali West',
      'Price': '92 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638448351187899857-2902240825182518.jpg?aio=w-745;h-419;crop;'},
     {'id': 44,
      'propertyName': 'Nahar Laurel and Lilac',
      'description': '2 Bedroom 968 Sq.Ft. Apartment in Chandivali Mumbai',
      'price': '₹ 1.95 Cr.',
      'City': 'Mumbai',
      'Locality': 'Chandivali',
      'Price': '1.95 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638405933216727598-110124060841841.jpeg?aio=w-745;h-419;crop;'},
     {'id': 45,
      'propertyName': "Royal Bungalow's ",
      'description': '2 Bedroom 650 Sq.Ft. Independent House in Virar East Mumbai',
      'price': '₹ 17.51 L',
      'City': 'Mumbai',
      'Locality': 'Virar East',
      'Price': '17.51 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638476078272488915-010424103707377.jpg?aio=w-745;h-419;crop;'},
     {'id': 46,
      'propertyName': 'Golden Heights Andheri West',
      'description': '2 Bedroom 1050 Sq.Ft. Apartment in Andheri West Mumbai',
      'price': '₹ 2.75 Cr.',
      'City': 'Mumbai',
      'Locality': 'Andheri West',
      'Price': '2.75 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638468030998280670-230324030459459.jpg?aio=w-745;h-419;crop;'},
     {'id': 47,
      'propertyName': 'Sunteck Avenue 4',
      'description': '3 Bedroom 902 Sq.Ft. Apartment in Goregaon West Mumbai',
      'price': '₹ 2.75 Cr.',
      'City': 'Mumbai',
      'Locality': 'Goregaon West',
      'Price': '2.75 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638406625469072115-1201240122262226.jpg?aio=w-745;h-419;crop;'}
  ],
  Hyderabad: [
    {'id': 1,
      'propertyName': 'Navabhoomi City Grande',
      'description': '297 Sq.Yd. Plot in Kongara Kalan Hyderabad',
      'price': '₹ 1.06 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Kongara Kalan',
      'Price': '1.06 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638532863574351649-0606240359175917.png?aio=w-745;h-419;crop;'},
     {'id': 2,
      'propertyName': 'Lansum Elena',
      'description': '4 Bedroom 3260 Sq.Ft. Apartment in Kokapet Hyderabad',
      'price': '₹ 2.6 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Kokapet',
      'Price': '2.6 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638510436740211367-110524050114114.webp?aio=w-745;h-419;crop;'},
     {'id': 3,
      'propertyName': 'Jayabheri Orange County',
      'description': '4 Bedroom 4055 Sq.Ft. Apartment in Gachibowli Hyderabad',
      'price': '₹ 4.46 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Gachibowli',
      'Price': '4.46 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638537310410068976-1106240730413041.jpg?aio=w-745;h-419;crop;'},
     {'id': 4,
      'propertyName': 'Grk Developers Lb Nagar',
      'description': '220 Sq.Yd. Plot in Lb Nagar Hyderabad',
      'price': '₹ 1.54 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Lb Nagar',
      'Price': '1.54 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638529582310549423-0206240850315031.jpg?aio=w-745;h-419;crop;'},
     {'id': 5,
      'propertyName': 'Sekher Plaza',
      'description': '3.5 Bedroom 3300 Sq.Ft. Builder Floor in Jubilee Hills Hyderabad',
      'price': '₹ 4.25 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Jubilee Hills',
      'Price': '4.25 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638531728594771999-0506240827392739.jpg?aio=w-745;h-419;crop;'},
     {'id': 6,
      'propertyName': "Jh Property's",
      'description': '167 Sq.Yd. Plot in Adibatla Hyderabad',
      'price': '₹ 46.76 L',
      'City': 'Hyderabad',
      'Locality': 'Adibatla',
      'Price': '46.76 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638525099236256853-2805240418431843.png?aio=w-745;h-419;crop;'},
     {'id': 7,
      'propertyName': 'Star Towers Defence Colony',
      'description': '3 Bedroom 2535 Sq.Ft. Builder Floor in Gk Colony Hyderabad',
      'price': '₹ 1.95 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Gk Colony',
      'Price': '1.95 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638528145258768118-0106240455255525.png?aio=w-745;h-419;crop;'},
     {'id': 8,
      'propertyName': 'Grk Developers Injapur',
      'description': '250 Sq.Yd. Plot in Injapur Hyderabad',
      'price': '₹ 1.05 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Injapur',
      'Price': '1.05 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638529609908052483-0206240936303630.jpg?aio=w-745;h-419;crop;'},
     {'id': 9,
      'propertyName': 'Poulomi Avante',
      'description': '3 Bedroom 1840 Sq.Ft. Apartment in Kokapet Hyderabad',
      'price': '₹ 1.78 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Kokapet',
      'Price': '1.78 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638513695775390874-1505241132573257.jpeg?aio=w-745;h-419;crop;'},
     {'id': 10,
      'propertyName': 'Asian Sun City',
      'description': 'Commercial Office Space 10000 Sq.Ft. in Kothaguda Hyderabad',
      'price': '₹ 11 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Kothaguda',
      'Price': '11 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638538376347413705-130624010714714.jpg?aio=w-745;h-419;crop;'},
     {'id': 11,
      'propertyName': 'Tricolour Palm Cove',
      'description': '2 Bedroom 1094 Sq.Ft. Apartment in Uppal Hyderabad',
      'price': '₹ 75 L',
      'City': 'Hyderabad',
      'Locality': 'Uppal',
      'Price': '75 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638538684391833829-1306240940394039.jpg?aio=w-745;h-419;crop;'},
     {'id': 12,
      'propertyName': 'Nandana Vanam Farm Land Kandukur, Maheswaram',
      'description': '221 Sq.Yd. Plot in Kandukur Hyderabad',
      'price': '₹ 19.89 L',
      'City': 'Hyderabad',
      'Locality': 'Kandukur',
      'Price': '19.89 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638472137229730442-280324090842842.jpg?aio=w-745;h-419;crop;'},
     {'id': 13,
      'propertyName': 'Jb Infra Group ',
      'description': '167 Sq.Yd. Plot in Ibrahimpatnam Hyderabad',
      'price': '₹ 23.31 L',
      'City': 'Hyderabad',
      'Locality': 'Ibrahimpatnam',
      'Price': '23.31 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638506884225149438-0705240220222022.jpg?aio=w-745;h-419;crop;'},
     {'id': 14,
      'propertyName': 'Aparna Cyber Star',
      'description': '2 Bedroom 1348 Sq.Ft. Apartment in Osman Nagar Hyderabad',
      'price': '₹ 80.88 L',
      'City': 'Hyderabad',
      'Locality': 'Osman Nagar',
      'Price': '80.88 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638492071976565654-2004241053175317.jpeg?aio=w-745;h-419;crop;'},
     {'id': 15,
      'propertyName': 'Vasavi Atlantis',
      'description': '3 Bedroom 1760 Sq.Ft. Apartment in Puppalaguda Hyderabad',
      'price': '₹ 1.19 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Puppalaguda',
      'Price': '1.19 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638488086084220957-150424081008108.jpeg?aio=w-745;h-419;crop;'},
     {'id': 16,
      'propertyName': 'Land Mark County Kothur',
      'description': '500 Sq.Yd. Plot in Kothur Hyderabad',
      'price': '₹ 1.2 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Kothur',
      'Price': '1.2 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638513640759388856-150524100115115.jpg?aio=w-745;h-419;crop;'},
     {'id': 17,
      'propertyName': 'Glentree Pharma County',
      'description': '254 Sq.Yd. Plot in Nandiwanaparthy Hyderabad',
      'price': '₹ 26.66 L',
      'City': 'Hyderabad',
      'Locality': 'Nandiwanaparthy',
      'Price': '26.66 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638508649827918506-090524032302232.jpg?aio=w-745;h-419;crop;'},
     {'id': 18,
      'propertyName': 'Raghava Wave',
      'description': '3 Bedroom 1660 Sq.Ft. Apartment in Tellapur Hyderabad',
      'price': '₹ 96.28 L',
      'City': 'Hyderabad',
      'Locality': 'Tellapur',
      'Price': '96.28 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638520095387164157-2205240918581858.jpeg?aio=w-745;h-419;crop;'},
     {'id': 19,
      'propertyName': 'Shivantha Gardenia',
      'description': '2 Bedroom 1030 Sq.Ft. Apartment in Isnapur Hyderabad',
      'price': '₹ 49 L',
      'City': 'Hyderabad',
      'Locality': 'Isnapur',
      'Price': '49 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638527019701710663-3005240939303930.jpg?aio=w-745;h-419;crop;'},
     {'id': 20,
      'propertyName': 'Rainbow County',
      'description': '380 Sq.Yd. Plot in Shadnagar Hyderabad',
      'price': '₹ 70.3 L',
      'City': 'Hyderabad',
      'Locality': 'Shadnagar',
      'Price': '70.3 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638490444479628436-1804240140474047.jpg?aio=w-745;h-419;crop;'},
     {'id': 21,
      'propertyName': 'Candeur Crescent',
      'description': '3 Bedroom 1958 Sq.Ft. Apartment in Serilingampally Hyderabad',
      'price': '₹ 1.37 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Serilingampally',
      'Price': '1.37 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638488944721938607-160424080112112.jpeg?aio=w-745;h-419;crop;'},
     {'id': 22,
      'propertyName': 'RK Oxygen Valley',
      'description': '3 Bedroom 1920 Sq.Ft. Apartment in Bollaram Hyderabad',
      'price': '₹ 1.2 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Bollaram',
      'Price': '1.2 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638512836448716177-1405241140444044.jpg?aio=w-745;h-419;crop;'},
     {'id': 23,
      'propertyName': 'Primark North Wave',
      'description': '2 Bedroom 1270 Sq.Ft. Apartment in Bahadurpally Hyderabad',
      'price': '₹ 68 L',
      'City': 'Hyderabad',
      'Locality': 'Bahadurpally',
      'Price': '68 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638484663433460120-110424090543543.jpg?aio=w-745;h-419;crop;'},
     {'id': 24,
      'propertyName': 'Vasudaika Cosmo Celesse',
      'description': '301 Sq.Yd. Plot in Kandukur Hyderabad',
      'price': '₹ 33.10 L',
      'City': 'Hyderabad',
      'Locality': 'Kandukur',
      'Price': '33.10 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638517237572562291-1905240155575557.jpg?aio=w-745;h-419;crop;'},
     {'id': 25,
      'propertyName': 'Prestige Beverly Hills',
      'description': '3 Bedroom 1796 Sq.Ft. Apartment in Kokapet Hyderabad',
      'price': '₹ 1.76 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Kokapet',
      'Price': '1.76 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638511242963548097-1205240324562456.jpg?aio=w-745;h-419;crop;'},
     {'id': 26,
      'propertyName': 'Beauty Green Farms ',
      'description': '1000 Sq.Yd. Plot in Shamshabad Hyderabad',
      'price': '₹ 1.05 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Shamshabad',
      'Price': '1.05 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638536402848009247-100624061804184.jpg?aio=w-745;h-419;crop;'},
     {'id': 27,
      'propertyName': 'The Prestige City Bellagio',
      'description': '2 Bedroom 1548 Sq.Ft. Apartment in Rajendra Nagar Hyderabad',
      'price': '₹ 1.11 Cr.',
      'City': 'Hyderabad',
      'Locality': 'Rajendra Nagar',
      'Price': '1.11 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638527682387611017-310524040358358.jpg?aio=w-745;h-419;crop;'}
    // Add more Hyderabad properties here if needed
  ],
  Bangalore: [
    {'id': 1,
      'propertyName': 'Sobha Dream Gardens',
      'description': '2 Bedroom 1059 Sq.Ft. Apartment in Thanisandra Main Road Bangalore',
      'price': '₹ 1.02 Cr.',
      'City': 'Bangalore',
      'Locality': 'Thanisandra Main Road',
      'Price': '1.02 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638435194090652944-1402240256495649.jpeg?aio=w-745;h-419;crop;'},
     {'id': 2,
      'propertyName': 'Independent House',
      'description': '3 Bedroom 1100 Sq.Ft. Independent House in K Chudahalli Bangalore',
      'price': '₹ 1.25 Cr.',
      'City': 'Bangalore',
      'Locality': 'K Chudahalli',
      'Price': '1.25 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638462048498402254-160324045409549.jpeg?aio=w-745;h-419;crop;'},
     {'id': 3,
      'propertyName': ' Pa  Hampi Hideaway Villa ',
      'description': '2 Bedroom 1800 Sq.Ft. Villa in Bannerghatta Jigani Road Bangalore',
      'price': '₹ 65 L',
      'City': 'Bangalore',
      'Locality': 'Bannerghatta Jigani Road',
      'Price': '65 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638461901518538830-1603241249114911.jpg?aio=w-745;h-419;crop;'},
     {'id': 4,
      'propertyName': 'Shriram Raynal Gardens',
      'description': '1200 Sq.Ft. Plot in Magadi Road Bangalore',
      'price': '₹ 42.04 L',
      'City': 'Bangalore',
      'Locality': 'Magadi Road',
      'Price': '42.04 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638459215432968268-1303241012231223.jpg?aio=w-745;h-419;crop;'},
     {'id': 5,
      'propertyName': ' Advika Luxurious Villa ',
      'description': '3 Bedroom 1800 Sq.Ft. Villa in Bannerghatta Jigani Road Bangalore',
      'price': '₹ 89 L',
      'City': 'Bangalore',
      'Locality': 'Bannerghatta Jigani Road',
      'Price': '89 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638465329168308496-200324120156156.jpg?aio=w-745;h-419;crop;'},
     {'id': 6,
      'propertyName': 'Provident Park Square',
      'description': '1.5 Bedroom 700 Sq.Ft. Apartment in Kanakapura Road Bangalore',
      'price': '₹ 65 L',
      'City': 'Bangalore',
      'Locality': 'Kanakapura Road',
      'Price': '65 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638436138949810987-1502240511341134.jpeg?aio=w-745;h-419;crop;'},
     {'id': 7,
      'propertyName': 'Sobha Neopolis',
      'description': '4 Bedroom 2481 Sq.Ft. Apartment in Panathur Bangalore',
      'price': '₹ 3.75 Cr.',
      'City': 'Bangalore',
      'Locality': 'Panathur',
      'Price': '3.75 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638506731192407889-070524100519519.jpeg?aio=w-745;h-419;crop;'},
     {'id': 8,
      'propertyName': 'Chowriappa Constellation',
      'description': '3 Bedroom 1702 Sq.Ft. Apartment in Kothanur Bangalore',
      'price': '₹ 1.1 Cr.',
      'City': 'Bangalore',
      'Locality': 'Kothanur',
      'Price': '1.1 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638446536561651286-270224060056056.jpeg?aio=w-745;h-419;crop;'},
     {'id': 9,
      'propertyName': 'Sobha City Santorini',
      'description': '3 Bedroom 1740 Sq.Ft. Apartment in Kannur Bangalore',
      'price': '₹ 1.95 Cr.',
      'City': 'Bangalore',
      'Locality': 'Kannur',
      'Price': '1.95 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638447568955938526-2802241041354135.jpeg?aio=w-745;h-419;crop;'},
     {'id': 10,
      'propertyName': 'Pioneer KRS Park Royal',
      'description': '3 Bedroom 1541 Sq.Ft. Apartment in Mysore Road Bangalore',
      'price': '₹ 1.17 Cr.',
      'City': 'Bangalore',
      'Locality': 'Mysore Road',
      'Price': '1.17 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638410997800784897-1701240249404940.jpeg?aio=w-745;h-419;crop;'},
     {'id': 11,
      'propertyName': 'Oraiyan Prestine City ',
      'description': '1200 Sq.Ft. Plot in Nelamangala - Chikkaballapura Road Bangalore',
      'price': '₹ 32.94 L',
      'City': 'Bangalore',
      'Locality': 'Nelamangala - Chikkaballapura Road',
      'Price': '32.94 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638521687741685013-2405240532543254.jpg?aio=w-745;h-419;crop;'},
     {'id': 12,
      'propertyName': 'Bk Villas',
      'description': '2 Bedroom 1200 Sq.Ft. Villa in Bannerghatta Jigani Road Bangalore',
      'price': '₹ 71.38 L',
      'City': 'Bangalore',
      'Locality': 'Bannerghatta Jigani Road',
      'Price': '71.38 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638530154306598737-0306241243504350.jpg?aio=w-745;h-419;crop;'},
     {'id': 13,
      'propertyName': 'Jr Green Park',
      'description': '2400 Sq.Yd. Plot in Hosur Road Bangalore',
      'price': '₹ 1.32 Cr.',
      'City': 'Bangalore',
      'Locality': 'Hosur Road',
      'Price': '1.32 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638516310997814661-1805241211391139.jpg?aio=w-745;h-419;crop;'},
     {'id': 14,
      'propertyName': '3bhk In  Godrej Royale Woods Boovanahalli',
      'description': '3 Bedroom 1504 Sq.Ft. Apartment in Boovanahalli Bangalore',
      'price': '₹ 98 L',
      'City': 'Bangalore',
      'Locality': 'Boovanahalli',
      'Price': '98 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638443953510853072-2402240615511551.jpeg?aio=w-745;h-419;crop;'},
     {'id': 15,
      'propertyName': 'Druthi Nice City',
      'description': '1280 Sq.Yd. Plot in Begur Road Bangalore',
      'price': '₹ 83.20 L',
      'City': 'Bangalore',
      'Locality': 'Begur Road',
      'Price': '83.20 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638463629503538325-1803241249104910.jpg?aio=w-745;h-419;crop;'},
     {'id': 16,
      'propertyName': 'Aashrithaa Brindavana',
      'description': '1200 Sq.Yd. Plot in Kanakapura Road Bangalore',
      'price': '₹ 46.95 L',
      'City': 'Bangalore',
      'Locality': 'Kanakapura Road',
      'Price': '46.95 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638530235110798844-0306240258315831.jpg?aio=w-745;h-419;crop;'},
     {'id': 17,
      'propertyName': 'Goyal Orchid Piccadilly',
      'description': '2 Bedroom 1176 Sq.Ft. Apartment in Thanisandra Main Road Bangalore',
      'price': '₹ 1.2 Cr.',
      'City': 'Bangalore',
      'Locality': 'Thanisandra Main Road',
      'Price': '1.2 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638467470600219458-220324113100310.jpg?aio=w-745;h-419;crop;'},
     {'id': 18,
      'propertyName': 'Bhartiya Nikoo Homes',
      'description': '1.5 Bedroom 930 Sq.Ft. Apartment in Thanisandra Main Road Bangalore',
      'price': '₹ 1.2 Cr.',
      'City': 'Bangalore',
      'Locality': 'Thanisandra Main Road',
      'Price': '1.2 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638429174529220010-0702240344124412.jpg?aio=w-745;h-419;crop;'},
     {'id': 19,
      'propertyName': 'Kings Dell ',
      'description': '1200 Sq.Yd. Plot in Bagaluru Bangalore',
      'price': '₹ 26.10 L',
      'City': 'Bangalore',
      'Locality': 'Bagaluru',
      'Price': '26.10 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638476515025774844-020424104502452.jpg?aio=w-745;h-419;crop;'},
     {'id': 20,
      'propertyName': 'Evergreen Homes Yelahanka ',
      'description': '3 Bedroom 1620 Sq.Ft. Apartment in Aditya Nagar Bangalore',
      'price': '₹ 1.35 Cr.',
      'City': 'Bangalore',
      'Locality': 'Aditya Nagar',
      'Price': '1.35 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638488701975328163-1604240116371637.jpeg?aio=w-745;h-419;crop;'},
     {'id': 21,
      'propertyName': 'Mahshree Dream Villa',
      'description': '800 Sq.Ft. Plot in Anjanapura Bangalore',
      'price': '₹ 52.66 L',
      'City': 'Bangalore',
      'Locality': 'Anjanapura',
      'Price': '52.66 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638403135183119950-0801241225182518.jpeg?aio=w-745;h-419;crop;'},
     {'id': 22,
      'propertyName': 'Rd Properties ',
      'description': '4 Bedroom 1201 Sq.Ft. Villa in Gottigere Bangalore',
      'price': '₹ 1.44 Cr.',
      'City': 'Bangalore',
      'Locality': 'Gottigere',
      'Price': '1.44 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638460296695320453-1403240414291429.jpg?aio=w-745;h-419;crop;'},
     {'id': 23,
      'propertyName': 'Concorde Cuppertino',
      'description': '6 Bedroom 1600 Sq.Ft. Villa in Electronic City Phase I Bangalore',
      'price': '₹ 4.00 Cr.',
      'City': 'Bangalore',
      'Locality': 'Electronic City Phase I',
      'Price': '4.00 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638458312243859224-12032409070474.jpg?aio=w-745;h-419;crop;'},
     {'id': 24,
      'propertyName': 'Mittal Elanza',
      'description': '3 Bedroom 1625 Sq.Ft. Apartment in Kogilu Bangalore',
      'price': '₹ 1.63 Cr.',
      'City': 'Bangalore',
      'Locality': 'Kogilu',
      'Price': '1.63 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638509785572724070-1005241055575557.jpg?aio=w-745;h-419;crop;'},
     {'id': 25,
      'propertyName': 'Vistaara Villa  ks',
      'description': '2 Bedroom 1050 Sq.Ft. Villa in Anjanapura Bangalore',
      'price': '₹ 89.16 L',
      'City': 'Bangalore',
      'Locality': 'Anjanapura',
      'Price': '89.16 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638452630329797275-0503240717121712.jpg?aio=w-745;h-419;crop;'},
     {'id': 26,
      'propertyName': 'Brigade El Dorado',
      'description': '2.5 Bedroom 1025 Sq.Ft. Apartment in Bagaluru Bangalore',
      'price': '₹ 81 L',
      'City': 'Bangalore',
      'Locality': 'Bagaluru',
      'Price': '81 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638512937933283309-1405240229532953.webp?aio=w-745;h-419;crop;'},
     {'id': 27,
      'propertyName': 'Flat For Sale',
      'description': '2 Bedroom 848 Sq.Ft. Apartment in Queens Road Bangalore',
      'price': '₹ 1.2 Cr.',
      'City': 'Bangalore',
      'Locality': 'Queens Road',
      'Price': '1.2 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638524181407529705-270524024900490.jpeg?aio=w-745;h-419;crop;'},
     {'id': 28,
      'propertyName': 'Smv Layout',
      'description': '1200 Sq.Ft. Plot in Smv Layout Bangalore',
      'price': '₹ 1.38 Cr.',
      'City': 'Bangalore',
      'Locality': 'Smv Layout',
      'Price': '1.38 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638431717197835819-1002240221592159.jpg?aio=w-745;h-419;crop;'},
     {'id': 29,
      'propertyName': ' Pa Heavenly Heights Villa',
      'description': '2 Bedroom 900 Sq.Ft. Villa in Kirloskar Layout Bangalore',
      'price': '₹ 90 L',
      'City': 'Bangalore',
      'Locality': 'Kirloskar Layout',
      'Price': '90 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638461048885041463-15032401080888.jpg?aio=w-745;h-419;crop;'},
     {'id': 30,
      'propertyName': 'Prestige Primrose Hills',
      'description': '2 Bedroom 867 Sq.Ft. Apartment in Banashankari 6th Stage Bangalore',
      'price': '₹ 83 L',
      'City': 'Bangalore',
      'Locality': 'Banashankari 6th Stage',
      'Price': '83 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638446466338413554-270224040353353.jpg?aio=w-745;h-419;crop;'},
     {'id': 31,
      'propertyName': 'Nandagokula Vrundavana',
      'description': '1200 Sq.Ft. Plot in Rajanukunte Bangalore',
      'price': '₹ 11.4 L',
      'City': 'Bangalore',
      'Locality': 'Rajanukunte',
      'Price': '11.4 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638412751843080159-190124033304334.jpg?aio=w-745;h-419;crop;'},
     {'id': 32,
      'propertyName': 'Bagalur rd',
      'description': '1200 Sq.Ft. Plot in Bagalur rd Bangalore',
      'price': '₹ 27.6 L',
      'City': 'Bangalore',
      'Locality': 'Bagalur rd',
      'Price': '27.6 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638448183246933506-2902240345244524.jpg?aio=w-745;h-419;crop;'},
     {'id': 33,
      'propertyName': 'Primrose - Z One ',
      'description': '2 Bedroom 600 Sq.Ft. Villa in Bangalore Central Jail Bangalore',
      'price': '₹ 15.27 L',
      'City': 'Bangalore',
      'Locality': 'Bangalore Central Jail',
      'Price': '15.27 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638441097970955611-2102241056375637.jpg?aio=w-745;h-419;crop;'},
     {'id': 34,
      'propertyName': 'Mahaveer Celesse',
      'description': '2 Bedroom 1179 Sq.Ft. Apartment in Yelahanka Bangalore',
      'price': '₹ 82 L',
      'City': 'Bangalore',
      'Locality': 'Yelahanka',
      'Price': '82 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638476800412446287-0204240640414041.jpg?aio=w-745;h-419;crop;'},
     {'id': 35,
      'propertyName': 'Prestige Primrose Hills',
      'description': '4 Bedroom 1765 Sq.Ft. Apartment in Banashankari 6th Stage Bangalore',
      'price': '₹ 1.85 Cr.',
      'City': 'Bangalore',
      'Locality': 'Banashankari 6th Stage',
      'Price': '1.85 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638460940283525060-15032410070878.jpg?aio=w-745;h-419;crop;'},
     {'id': 36,
      'propertyName': 'Sai Nest Horamavu',
      'description': '1496 Sq.Ft. Plot in Horamavu Bangalore',
      'price': '₹ 77.79 L',
      'City': 'Bangalore',
      'Locality': 'Horamavu',
      'Price': '77.79 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638460157415659852-1403241222212221.jpeg?aio=w-745;h-419;crop;'},
     {'id': 37,
      'propertyName': 'Assetz Bloom And Dell',
      'description': '3 Bedroom 2039 Sq.Ft. Apartment in Whitefield Road Bangalore',
      'price': '₹ 2.11 Cr.',
      'City': 'Bangalore',
      'Locality': 'Whitefield Road',
      'Price': '2.11 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638445688534316162-2602240627332733.jpg?aio=w-745;h-419;crop;'},
     {'id': 38,
      'propertyName': 'Flat For Sale',
      'description': '3 Bedroom 1706 Sq.Ft. Apartment in Residency Road Bangalore',
      'price': '₹ 3.25 Cr.',
      'City': 'Bangalore',
      'Locality': 'Residency Road',
      'Price': '3.25 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638524222045302510-2705240356445644.jpeg?aio=w-745;h-419;crop;'},
     {'id': 39,
      'propertyName': 'Meenakshi House ci',
      'description': '2 Bedroom 1200 Sq.Ft. Villa in Mysore Road Bangalore',
      'price': '₹ 75.60 L',
      'City': 'Bangalore',
      'Locality': 'Mysore Road',
      'Price': '75.60 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638462030992439130-1603240424592459.png?aio=w-745;h-419;crop;'},
     {'id': 40,
      'propertyName': 'Mm Old Cottage ',
      'description': '2 Bedroom 1200 Sq.Ft. Villa in Bannerghatta Bangalore',
      'price': '₹ 86.95 L',
      'City': 'Bangalore',
      'Locality': 'Bannerghatta',
      'Price': '86.95 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638461046001504381-150324010320320.jpg?aio=w-745;h-419;crop;'},
     {'id': 41,
      'propertyName': 'Sunkadakatte',
      'description': '1200 Sq.Ft. Plot in Sunkadakatte Bangalore',
      'price': '₹ 78.58 L',
      'City': 'Bangalore',
      'Locality': 'Sunkadakatte',
      'Price': '78.58 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638500055425532929-290424043902392.jpg?aio=w-745;h-419;crop;'},
     {'id': 42,
      'propertyName': 'Tg Solitaire',
      'description': '3 Bedroom 1650 Sq.Ft. Apartment in Hulimavu Bangalore',
      'price': '₹ 88 L',
      'City': 'Bangalore',
      'Locality': 'Hulimavu',
      'Price': '88 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638406790186446802-1201240556585658.jpg?aio=w-745;h-419;crop;'},
     {'id': 43,
      'propertyName': 'Provident Botanico',
      'description': '2 Bedroom 950 Sq.Ft. Apartment in Whitefield Bangalore',
      'price': '₹ 80 L',
      'City': 'Bangalore',
      'Locality': 'Whitefield',
      'Price': '80 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638474280079515422-300324084007407.jpg?aio=w-745;h-419;crop;'},
     {'id': 44,
      'propertyName': 'Goyal Orchid Life',
      'description': '2 Bedroom 923 Sq.Ft. Apartment in Gunjur Bangalore',
      'price': '₹ 95 L',
      'City': 'Bangalore',
      'Locality': 'Gunjur',
      'Price': '95 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638507866150723237-0805240536553655.jpg?aio=w-745;h-419;crop;'},
     {'id': 45,
      'propertyName': 'Royal Meadows ',
      'description': '600 Sq.Ft. Plot in Bannerghatta Road Bangalore',
      'price': '₹ 11.99 L',
      'City': 'Bangalore',
      'Locality': 'Bannerghatta Road',
      'Price': '11.99 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638415350892773785-2201240344494449.jpg?aio=w-745;h-419;crop;'},
     {'id': 46,
      'propertyName': 'Sai Prasanthi Homes',
      'description': '2500 Sq.Yd. Plot in Jigani Bangalore',
      'price': '₹ 57.10 L',
      'City': 'Bangalore',
      'Locality': 'Jigani',
      'Price': '57.10 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638488566179410476-1604240930173017.jpg?aio=w-745;h-419;crop;'},
     {'id': 47,
      'propertyName': 'Aashrithaa Devine ',
      'description': '1200 Sq.Ft. Plot in Jigani Bangalore',
      'price': '₹ 31.19 L',
      'City': 'Bangalore',
      'Locality': 'Jigani',
      'Price': '31.19 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638402301664528270-070124011606166.jpg?aio=w-745;h-419;crop;'},
     {'id': 48,
      'propertyName': 'Naira Residence ',
      'description': '3 Bedroom 1500 Sq.Ft. Villa in Nelamangala - Chikkaballapura Road Bangalore',
      'price': '₹ 82 L',
      'City': 'Bangalore',
      'Locality': 'Nelamangala - Chikkaballapura Road',
      'Price': '82 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638472274684185999-2803241257485748.jpg?aio=w-745;h-419;crop;'},
     {'id': 49,
      'propertyName': 'Vishweshwashwaraiah Layout 6th Block',
      'description': '1200 Sq.Ft. Plot in Vishweshwaraiah Layout Bangalore',
      'price': '₹ 1.23 Cr.',
      'City': 'Bangalore',
      'Locality': 'Vishweshwaraiah Layout',
      'Price': '1.23 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/56e4b8b3-e9c2-4198-a63c-84656d0e3d7b.jpg?aio=w-745;h-419;crop;'}
  ]
,
  Pune : [
    {'id': 1,
    'propertyName': 'Majestique Towers',
    'description': '2 Bedroom 824 Sq.Ft. Apartment in Kharadi Pune',
    'price': '₹ 91.99 L',
    'City': 'Pune',
    'Locality': 'Kharadi',
    'Price': '91.99 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638492263161724851-2004240411561156.jpg?aio=w-745;h-419;crop;'},
   {'id': 2,
    'propertyName': 'Shree Samarth The Benchmark',
    'description': 'Commercial Office Space 721 Sq.Ft. in Pimple Saudagar Pune',
    'price': '₹ 1.66 Cr.',
    'City': 'Pune',
    'Locality': 'Pimple Saudagar',
    'Price': '1.66 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638492270904342677-2004240424502450.jpg?aio=w-745;h-419;crop;'},
   {'id': 3,
    'propertyName': 'TCG Panorama',
    'description': '1 Bedroom 600 Sq.Ft. Apartment in Ambegaon Budruk Pune',
    'price': '₹ 43 L',
    'City': 'Pune',
    'Locality': 'Ambegaon Budruk',
    'Price': '43 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638526612605450255-300524102100210.jpg?aio=w-745;h-419;crop;'},
   {'id': 4,
    'propertyName': 'Duville Riverdale',
    'description': '3 Bedroom 1190 Sq.Ft. Apartment in Kharadi Pune',
    'price': '₹ 1.65 Cr.',
    'City': 'Pune',
    'Locality': 'Kharadi',
    'Price': '1.65 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638436793114646527-1602241121512151.jpg?aio=w-745;h-419;crop;'},
   {'id': 5,
    'propertyName': 'Gada Anutham',
    'description': '1 Bedroom 592 Sq.Ft. Apartment in Hadapsar Pune',
    'price': '₹ 70 L',
    'City': 'Pune',
    'Locality': 'Hadapsar',
    'Price': '70 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638388445728907332-2212231222522252.jpeg?aio=w-745;h-419;crop;'},
   {'id': 6,
    'propertyName': 'Godrej Emerald Waters',
    'description': '3 Bedroom 1325 Sq.Ft. Apartment in Pimpri Pune',
    'price': '₹ 1.9 Cr.',
    'City': 'Pune',
    'Locality': 'Pimpri',
    'Price': '1.9 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638388662846319318-2212230624442444.png?aio=w-745;h-419;crop;'},
   {'id': 7,
    'propertyName': 'VTP Flamante',
    'description': '2 Bedroom 818 Sq.Ft. Apartment in Kharadi Pune',
    'price': '₹ 99.5 L',
    'City': 'Pune',
    'Locality': 'Kharadi',
    'Price': '99.5 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638430274499455149-0802241017291729.jpg?aio=w-745;h-419;crop;'},
   {'id': 8,
    'propertyName': 'Saidham Commercial Complex',
    'description': 'Commercial Office Space 260 Sq.Ft. in Bhosari Pune',
    'price': '₹ 19.5 L',
    'City': 'Pune',
    'Locality': 'Bhosari',
    'Price': '19.5 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638450571900339518-030324100630630.jpg?aio=w-745;h-419;crop;'},
   {'id': 9,
    'propertyName': 'Kumar Parth Towers',
    'description': '2 Bedroom 774 Sq.Ft. Apartment in Baner Pune',
    'price': '₹ 95.90 L',
    'City': 'Pune',
    'Locality': 'Baner',
    'Price': '95.90 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638405098891908535-100124065809589.jpg?aio=w-745;h-419;crop;'},
   {'id': 10,
    'propertyName': 'Saikrushna Midori Tower',
    'description': '2 Bedroom 912 Sq.Ft. Builder Floor in Mamurdi Pune',
    'price': '₹ 65.15 L',
    'City': 'Pune',
    'Locality': 'Mamurdi',
    'Price': '65.15 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638418683199113727-2601241218391839.jpg?aio=w-745;h-419;crop;'},
   {'id': 11,
    'propertyName': 'Kumar Parc Residences',
    'description': '2 Bedroom 828 Sq.Ft. Apartment in Hadapsar Pune',
    'price': '₹ 1.09 Cr.',
    'City': 'Pune',
    'Locality': 'Hadapsar',
    'Price': '1.09 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638456715311333175-1003241245314531.jpg?aio=w-745;h-419;crop;'},
   {'id': 12,
    'propertyName': 'Malnad Magna City',
    'description': '2 Bedroom 757 Sq.Ft. Apartment in Manjri Budruk Pune',
    'price': '₹ 62.9 L',
    'City': 'Pune',
    'Locality': 'Manjri Budruk',
    'Price': '62.9 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638453399905417177-0603240439503950.jpg?aio=w-745;h-419;crop;'},
   {'id': 13,
    'propertyName': 'Shubh Tristar',
    'description': '4 Bedroom 1707 Sq.Ft. Apartment in Koregaon Park Annexe Pune',
    'price': '₹ 2.18 Cr.',
    'City': 'Pune',
    'Locality': 'Koregaon Park Annexe',
    'Price': '2.18 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638519828853342335-2205240154455445.jpeg?aio=w-745;h-419;crop;'},
   {'id': 14,
    'propertyName': 'Goel Ganga Avanta',
    'description': '3 Bedroom 1219 Sq.Ft. Apartment in Mundhwa Pune',
    'price': '₹ 1.72 Cr.',
    'City': 'Pune',
    'Locality': 'Mundhwa',
    'Price': '1.72 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638440270343835386-2002241157145714.jpg?aio=w-745;h-419;crop;'},
   {'id': 15,
    'propertyName': 'Mantra Mirari',
    'description': '4 Bedroom 1916 Sq.Ft. Apartment in Koregaon Park Pune',
    'price': '₹ 2.95 Cr.',
    'City': 'Pune',
    'Locality': 'Koregaon Park',
    'Price': '2.95 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638526577950219736-3005240923152315.jpg?aio=w-745;h-419;crop;'},
   {'id': 16,
    'propertyName': 'Jhamtani Ace Atmosphere',
    'description': '3 Bedroom 1006 Sq.Ft. Apartment in Ravet Pune',
    'price': '₹ 1.05 Cr.',
    'City': 'Pune',
    'Locality': 'Ravet',
    'Price': '1.05 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638411814548749100-1801240130543054.jpg?aio=w-745;h-419;crop;'},
   {'id': 17,
    'propertyName': 'Bramhacorp The Collection',
    'description': '4 Bedroom 1651 Sq.Ft. Apartment in Kalyani Nagar Pune',
    'price': '₹ 2.5 Cr.',
    'City': 'Pune',
    'Locality': 'Kalyani Nagar',
    'Price': '2.5 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638450656073431139-0303241226472647.jpg?aio=w-745;h-419;crop;'},
   {'id': 18,
    'propertyName': 'Amanora Aspire Towerss',
    'description': '2 Bedroom 1100 Sq.Ft. Apartment in Hadapsar Pune',
    'price': '₹ 1.25 Cr.',
    'City': 'Pune',
    'Locality': 'Hadapsar',
    'Price': '1.25 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638421730030718386-3001241256435643.jpg?aio=w-745;h-419;crop;'},
   {'id': 19,
    'propertyName': 'Trinity Grace',
    'description': '2 Bedroom 780 Sq.Ft. Apartment in Wakad Pune',
    'price': '₹ 82.24 L',
    'City': 'Pune',
    'Locality': 'Wakad',
    'Price': '82.24 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638441277770205725-2102240356175617.jpg?aio=w-745;h-419;crop;'},
   {'id': 20,
    'propertyName': 'Nanded Madhuvanti',
    'description': '2 Bedroom 938 Sq.Ft. Apartment in Sinhagad Road Pune',
    'price': '₹ 72 L',
    'City': 'Pune',
    'Locality': 'Sinhagad Road',
    'Price': '72 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638481016393603968-0704240347194719.jpg?aio=w-745;h-419;crop;'},
   {'id': 21,
    'propertyName': 'Kumar Pebble Park',
    'description': '2 Bedroom 749 Sq.Ft. Apartment in Hadapsar Pune',
    'price': '₹ 58.6 L',
    'City': 'Pune',
    'Locality': 'Hadapsar',
    'Price': '58.6 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638481802998832974-0804240138193819.jpg?aio=w-745;h-419;crop;'},
   {'id': 22,
    'propertyName': 'Nyati Esteban',
    'description': '3 Bedroom 1214 Sq.Ft. Apartment in Undri Pune',
    'price': '₹ 1.06 Cr.',
    'City': 'Pune',
    'Locality': 'Undri',
    'Price': '1.06 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638461186197622870-1503240456595659.jpg?aio=w-745;h-419;crop;'},
   {'id': 23,
    'propertyName': 'Kumar 47 East A',
    'description': '3 Bedroom 1119 Sq.Ft. Apartment in Magarpatta Pune',
    'price': '₹ 1.21 Cr.',
    'City': 'Pune',
    'Locality': 'Magarpatta',
    'Price': '1.21 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638395511710087387-3012230439313931.jpg?aio=w-745;h-419;crop;'},
   {'id': 24,
    'propertyName': 'Somani Towers',
    'description': '2 Bedroom 680 Sq.Ft. Apartment in Punawale Pune',
    'price': '₹ 59.46 L',
    'City': 'Pune',
    'Locality': 'Punawale',
    'Price': '59.46 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638464471604395809-1903241212401240.jpg?aio=w-745;h-419;crop;'},
   {'id': 25,
    'propertyName': 'Brahma Aangan',
    'description': '1 Bedroom 630 Sq.Ft. Apartment in Wanowrie Pune',
    'price': '₹ 43 L',
    'City': 'Pune',
    'Locality': 'Wanowrie',
    'Price': '43 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638512967149808388-1405240318341834.jpg?aio=w-745;h-419;crop;'},
   {'id': 26,
    'propertyName': 'Kumar Parc Residences',
    'description': '3 Bedroom 1160 Sq.Ft. Apartment in Hadapsar Pune',
    'price': '₹ 1.5 Cr.',
    'City': 'Pune',
    'Locality': 'Hadapsar',
    'Price': '1.5 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638479374780204311-0504240611181118.jpg?aio=w-745;h-419;crop;'},
   {'id': 27,
    'propertyName': 'Godrej Horizon',
    'description': '2 Bedroom 887 Sq.Ft. Apartment in Mohammadwadi Pune',
    'price': '₹ 72 L',
    'City': 'Pune',
    'Locality': 'Mohammadwadi',
    'Price': '72 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638511885162893127-1305240915161516.gif?aio=w-745;h-419;crop;'},
   {'id': 28,
    'propertyName': 'Amrut Runwal Daffodils',
    'description': '3 Bedroom 2498 Sq.Ft. Apartment in Nibm Pune',
    'price': '₹ 1.1 Cr.',
    'City': 'Pune',
    'Locality': 'Nibm',
    'Price': '1.1 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638478278030212362-0404241143234323.jpg?aio=w-745;h-419;crop;'},
   {'id': 29,
    'propertyName': 'Nyati Wind Chimes',
    'description': '3.5 Bedroom 2400 Sq.Ft. Apartment in Undri Pune',
    'price': '₹ 1.8 Cr.',
    'City': 'Pune',
    'Locality': 'Undri',
    'Price': '1.8 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638461827220851759-1603241045224522.jpg?aio=w-745;h-419;crop;'},
   {'id': 30,
    'propertyName': 'Tribeca Yoo One',
    'description': '4 Bedroom 1958 Sq.Ft. Apartment in Nibm Pune',
    'price': '₹ 2.95 Cr.',
    'City': 'Pune',
    'Locality': 'Nibm',
    'Price': '2.95 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638429955927643439-0802240126322632.jpg?aio=w-745;h-419;crop;'},
   {'id': 31,
    'propertyName': 'Platinum Marvelle',
    'description': '4 Bedroom 1700 Sq.Ft. Apartment in Balewadi Pune',
    'price': '₹ 2.6 Cr.',
    'City': 'Pune',
    'Locality': 'Balewadi',
    'Price': '2.6 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638494018670456274-2204240457475747.jpeg?aio=w-745;h-419;crop;'},
   {'id': 32,
    'propertyName': 'Surana Zinnea',
    'description': '3 Bedroom 1402 Sq.Ft. Apartment in Bavdhan Pune',
    'price': '₹ 1.5 Cr.',
    'City': 'Pune',
    'Locality': 'Bavdhan',
    'Price': '1.5 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638461779759080519-1603240926152615.jpg?aio=w-745;h-419;crop;'},
   {'id': 33,
    'propertyName': 'Arko Star Vista',
    'description': '2 Bedroom 716 Sq.Ft. Apartment in Ravet Pune',
    'price': '₹ 68.5 L',
    'City': 'Pune',
    'Locality': 'Ravet',
    'Price': '68.5 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638475744369634366-0104240120362036.jpg?aio=w-745;h-419;crop;'},
   {'id': 34,
    'propertyName': 'Gera GreensVille SkyVillas',
    'description': '4 Bedroom 2909 Sq.Ft. Apartment in Kharadi Pune',
    'price': '₹ 3.62 Cr.',
    'City': 'Pune',
    'Locality': 'Kharadi',
    'Price': '3.62 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638491394099205796-190424040329329.jpg?aio=w-745;h-419;crop;'},
   {'id': 35,
    'propertyName': 'Gagan Adira',
    'description': '2 Bedroom 781 Sq.Ft. Apartment in Wagholi Pune',
    'price': '₹ 72 L',
    'City': 'Pune',
    'Locality': 'Wagholi',
    'Price': '72 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638433778047034643-1202241136443644.jpg?aio=w-745;h-419;crop;'},
   {'id': 36,
    'propertyName': 'Kumar Princetown',
    'description': '2 Bedroom 800 Sq.Ft. Apartment in Undri Pune',
    'price': '₹ 70 L',
    'City': 'Pune',
    'Locality': 'Undri',
    'Price': '70 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638386962750538703-2012230711151115.jpg?aio=w-745;h-419;crop;'},
   {'id': 37,
    'propertyName': 'Rajesh Complex Tingre Nagar',
    'description': '3 Bedroom 865 Sq.Ft. Apartment in Tingre Nagar Pune',
    'price': '₹ 98 L',
    'City': 'Pune',
    'Locality': 'Tingre Nagar',
    'Price': '98 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638533625313534726-070624010851851.jpg?aio=w-745;h-419;crop;'},
   {'id': 38,
    'propertyName': 'Godrej Emerald Waters',
    'description': '2 Bedroom 914 Sq.Ft. Builder Floor in Pimpri Pune',
    'price': '₹ 1.3 Cr.',
    'City': 'Pune',
    'Locality': 'Pimpri',
    'Price': '1.3 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/788b0ac6-1f3f-43b0-8cf2-6b4e4f010acc.jpg?aio=w-745;h-419;crop;'},
   {'id': 39,
    'propertyName': 'Kumar Palmgrove',
    'description': '2 Bedroom 875 Sq.Ft. Apartment in Kondhwa Pune',
    'price': '₹ 68 L',
    'City': 'Pune',
    'Locality': 'Kondhwa',
    'Price': '68 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638447321356752395-2802240348554855.jpg?aio=w-745;h-419;crop;'},
   {'id': 40,
    'propertyName': 'Royal Recidency',
    'description': '2 Bedroom 777 Sq.Ft. Apartment in Wakad Pune',
    'price': '₹ 76 L',
    'City': 'Pune',
    'Locality': 'Wakad',
    'Price': '76 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638466393215698927-2103240535213521.png?aio=w-745;h-419;crop;'},
   {'id': 41,
    'propertyName': 'Om Angel Hills Apartmentss',
    'description': '1 Bedroom 594 Sq.Ft. Builder Floor in Talegaon Dabhade Pune',
    'price': '₹ 22.5 L',
    'City': 'Pune',
    'Locality': 'Talegaon Dabhade',
    'Price': '22.5 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638472354507924159-2803240310501050.jpg?aio=w-745;h-419;crop;'},
   {'id': 42,
    'propertyName': 'Satymev Jayate Developers',
    'description': '3000 Sq.Ft. Plot in Khed Shivapur Pune',
    'price': '₹ 21 L',
    'City': 'Pune',
    'Locality': 'Khed Shivapur',
    'Price': '21 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638432535794180935-110224010619619.png?aio=w-745;h-419;crop;'},
   {'id': 43,
    'propertyName': 'Mahesh Society',
    'description': '2 Bedroom 850 Sq.Ft. Apartment in Bibwewadi Pune',
    'price': '₹ 65 L',
    'City': 'Pune',
    'Locality': 'Bibwewadi',
    'Price': '65 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638499186766310918-2804240431163116.jpg?aio=w-745;h-419;crop;'},
   {'id': 44,
    'propertyName': 'Amanora Chambers',
    'description': 'Commercial Office Space 1047 Sq.Ft. in Hadapsar Pune',
    'price': '₹ 1.29 Cr.',
    'City': 'Pune',
    'Locality': 'Hadapsar',
    'Price': '1.29 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638402196080965321-070124102008208.jpg?aio=w-745;h-419;crop;'},
   {'id': 45,
    'propertyName': 'Nanded City Antara At Nanded',
    'description': '2.5 Bedroom 1107 Sq.Ft. Apartment in Deccan Gymkhana Pune',
    'price': '₹ 1.15 Cr.',
    'City': 'Pune',
    'Locality': 'Deccan Gymkhana',
    'Price': '1.15 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638473056371537982-2903241040374037.jpeg?aio=w-745;h-419;crop;'},
   {'id': 46,
    'propertyName': 'Vijay Vanaz Pariwar CHS',
    'description': '2 Bedroom 750 Sq.Ft. Apartment in Kothrud Pune',
    'price': '₹ 95 L',
    'City': 'Pune',
    'Locality': 'Kothrud',
    'Price': '95 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638479165061601566-0504241221462146.jpg?aio=w-745;h-419;crop;'},
   {'id': 47,
    'propertyName': 'Mittal High Mont',
    'description': '2 Bedroom 750 Sq.Ft. Apartment in Hinjewadi Pune',
    'price': '₹ 75 L',
    'City': 'Pune',
    'Locality': 'Hinjewadi',
    'Price': '75 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638486342539974647-1304240744134413.jpg?aio=w-745;h-419;crop;'},
   {'id': 48,
    'propertyName': 'Nivasa Elevia',
    'description': '2 Bedroom 760 Sq.Ft. Apartment in Mundhwa Pune',
    'price': '₹ 65.5 L',
    'City': 'Pune',
    'Locality': 'Mundhwa',
    'Price': '65.5 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638405875977912314-1101240433173317.jpg?aio=w-745;h-419;crop;'},
   {'id': 49,
    'propertyName': 'Gera Island of Joy',
    'description': '3 Bedroom 1513 Sq.Ft. Apartment in Kharadi Pune',
    'price': '₹ 1.77 Cr.',
    'City': 'Pune',
    'Locality': 'Kharadi',
    'Price': '1.77 Cr.',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638485335994383150-1204240346394639.jpg?aio=w-745;h-419;crop;'},
   {'id': 50,
    'propertyName': 'Gagan Adira',
    'description': '2 Bedroom 806 Sq.Ft. Apartment in Wagholi Pune',
    'price': '₹ 72 L',
    'City': 'Pune',
    'Locality': 'Wagholi',
    'Price': '72 L',
    'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638437759838993922-170224021303133.jpg?aio=w-745;h-419;crop;'}],

  Kolkata : [
    {'id': 1,
      'propertyName': 'Merlin Rise',
      'description': '3 Bedroom 1187 Sq.Ft. Apartment in Rajarhat Kolkata',
      'price': '₹ 68.5 L',
      'City': 'Kolkata',
      'Locality': 'Rajarhat',
      'Price': '68.5 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638395525381082015-301223050218218.jpg?aio=w-745;h-419;crop;'},
     {'id': 2,
      'propertyName': 'Salarpuria Luxuria Heights',
      'description': '4 Bedroom 2381 Sq.Ft. Apartment in Tangra Kolkata',
      'price': '₹ 2 Cr.',
      'City': 'Kolkata',
      'Locality': 'Tangra',
      'Price': '2 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638390303676489982-2412230359275927.jpg?aio=w-745;h-419;crop;'},
     {'id': 3,
      'propertyName': 'Hiland Greens',
      'description': '2 Bedroom 712 Sq.Ft. Apartment in Maheshtala Kolkata',
      'price': '₹ 29.9 L',
      'City': 'Kolkata',
      'Locality': 'Maheshtala',
      'Price': '29.9 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638398235311213256-0201240818511851.jpg?aio=w-745;h-419;crop;'},
     {'id': 4,
      'propertyName': 'Meghna Apartments',
      'description': '3 Bedroom 1000 Sq.Ft. Apartment in Belgharia Kolkata',
      'price': '₹ 35 L',
      'City': 'Kolkata',
      'Locality': 'Belgharia',
      'Price': '35 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638488772712049829-1604240314311431.jpeg?aio=w-745;h-419;crop;'},
     {'id': 5,
      'propertyName': 'New Booking',
      'description': '2 Bedroom 800 Sq.Ft. Apartment in Hatiara Kolkata',
      'price': '₹ 24 L',
      'City': 'Kolkata',
      'Locality': 'Hatiara',
      'Price': '24 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638435565802247516-1502240116201620.jpeg?aio=w-745;h-419;crop;'},
     {'id': 6,
      'propertyName': 'Mg Square',
      'description': 'Commercial Office Space 833 Sq.Ft. in Bangur Avenue Kolkata',
      'price': '₹ 1.33 Cr.',
      'City': 'Kolkata',
      'Locality': 'Bangur Avenue',
      'Price': '1.33 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638508686630128616-0905240424232423.jpg?aio=w-745;h-419;crop;'},
     {'id': 7,
      'propertyName': 'Chandannagar',
      'description': '1 Bedroom 720 Sq.Ft. Independent House in Chandannagar Kolkata',
      'price': '₹ 70 L',
      'City': 'Kolkata',
      'Locality': 'Chandannagar',
      'Price': '70 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638409374658292466-1501240544254425.jpeg?aio=w-745;h-419;crop;'},
     {'id': 8,
      'propertyName': 'Arch Starwood',
      'description': '3 Bedroom 1800 Sq.Ft. Apartment in Chinar Park Kolkata',
      'price': '₹ 1.9 Cr.',
      'City': 'Kolkata',
      'Locality': 'Chinar Park',
      'Price': '1.9 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638430965416286060-090224052901291.jpg?aio=w-745;h-419;crop;'},
     {'id': 9,
      'propertyName': 'Eco Smart City ',
      'description': '565 Sq.Yd. Plot in New Town Kolkata',
      'price': '₹ 14 L',
      'City': 'Kolkata',
      'Locality': 'New Town',
      'Price': '14 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638470476800139940-260324110120120.jpg?aio=w-745;h-419;crop;'},
     {'id': 10,
      'propertyName': 'Joka Land',
      'description': '2160 Sq.Ft. Plot in Joka Kolkata',
      'price': '₹ 8.49 L',
      'City': 'Kolkata',
      'Locality': 'Joka',
      'Price': '8.49 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638505263837859137-0505240519431943.jpg?aio=w-745;h-419;crop;'},
     {'id': 11,
      'propertyName': 'Flat For Sale',
      'description': '2 Bedroom 890 Sq.Ft. Apartment in Uttarpara Kotrung Kolkata',
      'price': '₹ 27.00 L',
      'City': 'Kolkata',
      'Locality': 'Uttarpara Kotrung',
      'Price': '27.00 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638500959844730296-3004240546244624.jpeg?aio=w-745;h-419;crop;'},
     {'id': 12,
      'propertyName': 'Dankuni Housing Estate',
      'description': '1 Bedroom 500 Sq.Ft. Apartment in Dankuni Kolkata',
      'price': '₹ 16 L',
      'City': 'Kolkata',
      'Locality': 'Dankuni',
      'Price': '16 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638537069787309742-1106241249384938.jpg?aio=w-745;h-419;crop;'},
     {'id': 13,
      'propertyName': 'Southern Park',
      'description': '1440 Sq.Ft. Plot in Baruipur Kolkata',
      'price': '₹ 12 L',
      'City': 'Kolkata',
      'Locality': 'Baruipur',
      'Price': '12 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638419684035102712-270124040643643.jpg?aio=w-745;h-419;crop;'},
     {'id': 14,
      'propertyName': 'Kolkata New Town Downtown Mall ',
      'description': '4320 Sq.Ft. Plot in Uniworld City Kolkata',
      'price': '₹ 18 L',
      'City': 'Kolkata',
      'Locality': 'Uniworld City',
      'Price': '18 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638434240083716140-1302241226482648.jpg?aio=w-745;h-419;crop;'},
     {'id': 15,
      'propertyName': 'Tarama Apartment',
      'description': '2 Bedroom 620 Sq.Ft. Apartment in Keshtopur Kolkata',
      'price': '₹ 21 L',
      'City': 'Kolkata',
      'Locality': 'Keshtopur',
      'Price': '21 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638478433534872034-040424040233233.jpg?aio=w-745;h-419;crop;'},
     {'id': 16,
      'propertyName': 'Commercial Newtown Near Cc2',
      'description': 'Commercial Shop 441 Sq.Ft. in New Town Action Area ii Kolkata',
      'price': '₹ 74.97 L',
      'City': 'Kolkata',
      'Locality': 'New Town Action Area ii',
      'Price': '74.97 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638502850833251626-020524101803183.jpg?aio=w-745;h-419;crop;'},
     {'id': 17,
      'propertyName': 'Residential Apartment',
      'description': '4 Bedroom 2500 Sq.Ft. Apartment in Ballygunge Kolkata',
      'price': '₹ 3 Cr.',
      'City': 'Kolkata',
      'Locality': 'Ballygunge',
      'Price': '3 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638474094600928024-300324033100310.jpg?aio=w-745;h-419;crop;'},
     {'id': 18,
      'propertyName': 'Merlin Serenia',
      'description': '4 Bedroom 1629 Sq.Ft. Apartment in Bt Road Kolkata',
      'price': '₹ 1.42 Cr.',
      'City': 'Kolkata',
      'Locality': 'Bt Road',
      'Price': '1.42 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638427362930984205-0502240124532453.jpg?aio=w-745;h-419;crop;'},
     {'id': 19,
      'propertyName': 'Residential Apartment',
      'description': '3 Bedroom 1300 Sq.Ft. Apartment in Kasba Kolkata',
      'price': '₹ 75 L',
      'City': 'Kolkata',
      'Locality': 'Kasba',
      'Price': '75 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638399008970811277-0301240548174817.jpg?aio=w-745;h-419;crop;'},
     {'id': 20,
      'propertyName': 'Yeasini Enterprise ',
      'description': '2 Bedroom 900 Sq.Ft. Apartment in Jessore Road Kolkata',
      'price': '₹ 50 L',
      'City': 'Kolkata',
      'Locality': 'Jessore Road',
      'Price': '50 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638437790422643196-17022403040242.jpg?aio=w-745;h-419;crop;'},
     {'id': 21,
      'propertyName': 'Kolkata New Town ',
      'description': '12240 Sq.Ft. Plot in New Town Action AreA-Iii Kolkata',
      'price': '₹ 1.87 Cr.',
      'City': 'Kolkata',
      'Locality': 'New Town Action AreA-Iii',
      'Price': '1.87 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638425843036986024-0302240711431143.jpg?aio=w-745;h-419;crop;'},
     {'id': 22,
      'propertyName': 'Porshi Nagar',
      'description': '2 Bedroom 800 Sq.Ft. Apartment in Uttarpara Kolkata',
      'price': '₹ 39 L',
      'City': 'Kolkata',
      'Locality': 'Uttarpara',
      'Price': '39 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638416202536315526-2301240324132413.jpeg?aio=w-745;h-419;crop;'},
     {'id': 23,
      'propertyName': 'Rajwada Royal Gardens',
      'description': '2 Bedroom 1036 Sq.Ft. Apartment in Narendrapur Kolkata',
      'price': '₹ 53 L',
      'City': 'Kolkata',
      'Locality': 'Narendrapur',
      'Price': '53 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638417064886264772-2401240321282128.jpeg?aio=w-745;h-419;crop;'},
     {'id': 24,
      'propertyName': 'Plot For Sale',
      'description': '1462 Sq.Ft. Plot in Nepalgange Kolkata',
      'price': '₹ 11.7 L',
      'City': 'Kolkata',
      'Locality': 'Nepalgange',
      'Price': '11.7 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638463578995505620-1803241124592459.jpeg?aio=w-745;h-419;crop;'},
     {'id': 25,
      'propertyName': 'Zaraproject',
      'description': 'Commercial Office Space 985 Sq.Ft. in Camac st Kolkata',
      'price': '₹ 1.67 Cr.',
      'City': 'Kolkata',
      'Locality': 'Camac st',
      'Price': '1.67 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638424608125220733-0202240853325332.jpg?aio=w-745;h-419;crop;'},
     {'id': 26,
      'propertyName': 'Pithamchin Kalimpong ',
      'description': '2 Acre Plot in Kalimandir Kolkata',
      'price': '₹ 1.5 Cr.',
      'City': 'Kolkata',
      'Locality': 'Kalimandir',
      'Price': '1.5 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638477036243061936-0304240113441344.jpg?aio=w-745;h-419;crop;'},
     {'id': 27,
      'propertyName': 'Bhagvati Connect',
      'description': 'Commercial Office Space 500 Sq.Ft. in Bbd Bag Kolkata',
      'price': '₹ 75 L',
      'City': 'Kolkata',
      'Locality': 'Bbd Bag',
      'Price': '75 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638435992338156346-150224010713713.jpeg?aio=w-745;h-419;crop;'},
     {'id': 28,
      'propertyName': 'Godrej Seven',
      'description': '2 Bedroom 967 Sq.Ft. Apartment in Joka Kolkata',
      'price': '₹ 50 L',
      'City': 'Kolkata',
      'Locality': 'Joka',
      'Price': '50 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638508537150965178-0905241215151515.jpeg?aio=w-745;h-419;crop;'},
     {'id': 29,
      'propertyName': 'Sugam Urban Lakes',
      'description': '3 Bedroom 1026 Sq.Ft. Apartment in Konnagar Kolkata',
      'price': '₹ 34.63 L',
      'City': 'Kolkata',
      'Locality': 'Konnagar',
      'Price': '34.63 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638404056274229208-090124020027027.jpg?aio=w-745;h-419;crop;'},
     {'id': 30,
      'propertyName': 'Kalyani City Enclave',
      'description': '1800 Sq.Ft. Plot in Shyamnagar Kolkata',
      'price': '₹ 25 L',
      'City': 'Kolkata',
      'Locality': 'Shyamnagar',
      'Price': '25 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638429326157540507-0702240756555655.jpg?aio=w-745;h-419;crop;'},
     {'id': 31,
      'propertyName': 'PS One 10',
      'description': '4 Bedroom 1345 Sq.Ft. Apartment in New Town Kolkata',
      'price': '₹ 2.5 Cr.',
      'City': 'Kolkata',
      'Locality': 'New Town',
      'Price': '2.5 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638397225278395201-0101240415271527.jpg?aio=w-745;h-419;crop;'},
     {'id': 32,
      'propertyName': 'Orbit Ekam ',
      'description': 'Commercial Showroom 40000 Sq.Ft. in Minto Park Kolkata',
      'price': '₹ 28 Cr.',
      'City': 'Kolkata',
      'Locality': 'Minto Park',
      'Price': '28 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638474978466185775-31032404040646.jpg?aio=w-745;h-419;crop;'},
     {'id': 33,
      'propertyName': 'Narayana Apartment ',
      'description': '2 Bedroom 1318 Sq.Ft. Apartment in Dum Dum Kolkata',
      'price': '₹ 85.67 L',
      'City': 'Kolkata',
      'Locality': 'Dum Dum',
      'Price': '85.67 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638526950228645008-3005240743424342.jpg?aio=w-745;h-419;crop;'},
     {'id': 34,
      'propertyName': 'Aparna Villa',
      'description': '1 Bedroom 565 Sq.Ft. Apartment in Netaji Nagar Kolkata',
      'price': '₹ 25 L',
      'City': 'Kolkata',
      'Locality': 'Netaji Nagar',
      'Price': '25 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638391881457963163-261223114905495.jpeg?aio=w-745;h-419;crop;'},
     {'id': 35,
      'propertyName': 'White House',
      'description': '2 Bedroom 835 Sq.Ft. Apartment in New Shakuntala Park Kolkata',
      'price': '₹ 30 L',
      'City': 'Kolkata',
      'Locality': 'New Shakuntala Park',
      'Price': '30 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638537097669492494-110624013606366.jpg?aio=w-745;h-419;crop;'},
     {'id': 36,
      'propertyName': 'Pratiksha Apartment',
      'description': '3 Bedroom 1200 Sq.Ft. Apartment in Behala Kolkata',
      'price': '₹ 62 L',
      'City': 'Kolkata',
      'Locality': 'Behala',
      'Price': '62 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638473327572960239-2903240612371237.jpeg?aio=w-745;h-419;crop;'},
     {'id': 37,
      'propertyName': 'Shreekunj Apartment, Southern Avenue ',
      'description': '2 Bedroom 1100 Sq.Ft. Apartment in Southern Avenue Kolkata',
      'price': '₹ 85 L',
      'City': 'Kolkata',
      'Locality': 'Southern Avenue',
      'Price': '85 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638534767383466244-0806240852185218.jpg?aio=w-745;h-419;crop;'}
  ],
  Chennai : [
    {'id': 1,
      'propertyName': 'Alliance Jewel Of Chennai',
      'description': '2.5 Bedroom 1160 Sq.Ft. Apartment in Madhavaram Chennai',
      'price': '₹ 1.1 Cr.',
      'City': 'Chennai',
      'Locality': 'Madhavaram',
      'Price': '1.1 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638445504672240426-260224012107217.jpg?aio=w-745;h-419;crop;'},
     {'id': 2,
      'propertyName': 'Grand Sharps ',
      'description': '2 Bedroom 878 Sq.Ft. Apartment in Thoraipakkam Chennai',
      'price': '₹ 72 L',
      'City': 'Chennai',
      'Locality': 'Thoraipakkam',
      'Price': '72 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638406557206659816-1201241128402840.jpeg?aio=w-745;h-419;crop;'},
     {'id': 3,
      'propertyName': 'Staar Pro Chennai ',
      'description': '6 Bedroom 4800 Sq.Ft. Independent House in Pudupakkam Chennai',
      'price': '₹ 90 L',
      'City': 'Chennai',
      'Locality': 'Pudupakkam',
      'Price': '90 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638520783002643413-230524042500250.jpg?aio=w-745;h-419;crop;'},
     {'id': 4,
      'propertyName': 'Mogappair Site Mogappair Chennai',
      'description': '2 Bedroom 1100 Sq.Ft. Apartment in Mogappair Chennai',
      'price': '₹ 1 Cr.',
      'City': 'Chennai',
      'Locality': 'Mogappair',
      'Price': '1 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638464673485337866-190324054908498.jpeg?aio=w-745;h-419;crop;'},
     {'id': 5,
      'propertyName': 'Bbcl Midland Sholingnallur Chennai',
      'description': '2 Bedroom 1134 Sq.Ft. Apartment in Sholinganallur Chennai',
      'price': '₹ 72.57 L',
      'City': 'Chennai',
      'Locality': 'Sholinganallur',
      'Price': '72.57 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638517247661716958-1905240212461246.jpg?aio=w-745;h-419;crop;'},
     {'id': 6,
      'propertyName': 'Olympia Grande',
      'description': '2 Bedroom 1180 Sq.Ft. Apartment in Pallavaram Chennai',
      'price': '₹ 83 L',
      'City': 'Chennai',
      'Locality': 'Pallavaram',
      'Price': '83 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638531031316602381-040624010531531.jpeg?aio=w-745;h-419;crop;'},
     {'id': 7,
      'propertyName': 'LML Iconia',
      'description': '2 Bedroom 1163 Sq.Ft. Apartment in Kattupakkam Chennai',
      'price': '₹ 65.94 L',
      'City': 'Chennai',
      'Locality': 'Kattupakkam',
      'Price': '65.94 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638434085500597919-130224080910910.jpg?aio=w-745;h-419;crop;'},
     {'id': 8,
      'propertyName': 'Ssg Plots And Villas ',
      'description': '1600 Sq.Ft. Plot in Kelambakkam Chennai',
      'price': '₹ 79.98 L',
      'City': 'Chennai',
      'Locality': 'Kelambakkam',
      'Price': '79.98 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638529518978346559-020624070457457.jpg?aio=w-745;h-419;crop;'},
     {'id': 9,
      'propertyName': 'Lml Prakriti Phase 2 ',
      'description': '2 Bedroom 1046 Sq.Ft. Apartment in Tambaram Chennai',
      'price': '₹ 72.03 L',
      'City': 'Chennai',
      'Locality': 'Tambaram',
      'Price': '72.03 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638433577928239262-120224060312312.jpg?aio=w-745;h-419;crop;'},
     {'id': 10,
      'propertyName': 'Kuberan Nagar',
      'description': '867 Sq.Ft. Plot in Avadi Chennai',
      'price': '₹ 31.21 L',
      'City': 'Chennai',
      'Locality': 'Avadi',
      'Price': '31.21 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638435294276253453-1402240543474347.jpg?aio=w-745;h-419;crop;'},
     {'id': 11,
      'propertyName': 'Casa Grande Mannivakkam',
      'description': '957 Sq.Ft. Plot in Mannivakkam Chennai',
      'price': '₹ 35.40 L',
      'City': 'Chennai',
      'Locality': 'Mannivakkam',
      'Price': '35.40 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638479262251587432-050424030345345.jpg?aio=w-745;h-419;crop;'},
     {'id': 12,
      'propertyName': 'Megha Enclave',
      'description': '2 Bedroom 722 Sq.Ft. Apartment in Chromepet Chennai',
      'price': '₹ 52 L',
      'City': 'Chennai',
      'Locality': 'Chromepet',
      'Price': '52 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638477595681590295-030424044608468.jpg?aio=w-745;h-419;crop;'},
     {'id': 13,
      'propertyName': 'Sj Enclave',
      'description': '2 Bedroom 963 Sq.Ft. Apartment in West Mambalam Chennai',
      'price': '₹ 1.25 Cr.',
      'City': 'Chennai',
      'Locality': 'West Mambalam',
      'Price': '1.25 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638459380012530339-1303240246414641.jpg?aio=w-745;h-419;crop;'},
     {'id': 14,
      'propertyName': 'Casagrand Miro',
      'description': '1002 Sq.Ft. Plot in Mannivakkam Chennai',
      'price': '₹ 37.07 L',
      'City': 'Chennai',
      'Locality': 'Mannivakkam',
      'Price': '37.07 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638479269793102391-0504240316191619.jpg?aio=w-745;h-419;crop;'},
     {'id': 15,
      'propertyName': 'Jemi Residency',
      'description': '1 Bedroom 735 Sq.Ft. Apartment in Padi Chennai',
      'price': '₹ 57 L',
      'City': 'Chennai',
      'Locality': 'Padi',
      'Price': '57 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638502042744325387-0105241151145114.jpg?aio=w-745;h-419;crop;'},
     {'id': 16,
      'propertyName': 'Singhvi Garden ',
      'description': '3 Bedroom 1600 Sq.Ft. Villa in Siruseri Chennai',
      'price': '₹ 92 L',
      'City': 'Chennai',
      'Locality': 'Siruseri',
      'Price': '92 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638442678893014334-2302240651295129.jpg?aio=w-745;h-419;crop;'},
     {'id': 17,
      'propertyName': 'Yuga Kalpataru',
      'description': '3 Bedroom 1302 Sq.Ft. Apartment in Koyambedu Chennai',
      'price': '₹ 1.05 Cr.',
      'City': 'Chennai',
      'Locality': 'Koyambedu',
      'Price': '1.05 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638400824945790596-0501240814541454.jpeg?aio=w-745;h-419;crop;'},
     {'id': 18,
      'propertyName': 'Godrej Sunrise Estate Oragadam ',
      'description': '2000 Sq.Ft. Plot in Sriperumbudur Chennai',
      'price': '₹ 52 L',
      'City': 'Chennai',
      'Locality': 'Sriperumbudur',
      'Price': '52 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638470251165302417-2603240445164516.jpg?aio=w-745;h-419;crop;'},
     {'id': 19,
      'propertyName': 'Urban Tree Amethyst Haven ',
      'description': '1486 Sq.Ft. Plot in Thiruverkadu Chennai',
      'price': '₹ 81.70 L',
      'City': 'Chennai',
      'Locality': 'Thiruverkadu',
      'Price': '81.70 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638423920885555185-010224014808488.jpg?aio=w-745;h-419;crop;'},
     {'id': 20,
      'propertyName': 'Namma Family Amar Garden ',
      'description': '2482 Sq.Ft. Plot in Guduvanchery Chennai',
      'price': '₹ 53.36 L',
      'City': 'Chennai',
      'Locality': 'Guduvanchery',
      'Price': '53.36 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638435036027052815-1402241033223322.jpg?aio=w-745;h-419;crop;'},
     {'id': 21,
      'propertyName': '15 Kk Avenue ',
      'description': '2 Bedroom 750 Sq.Ft. Villa in Potheri Chennai',
      'price': '₹ 38.99 L',
      'City': 'Chennai',
      'Locality': 'Potheri',
      'Price': '38.99 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638435017714660237-140224100251251.jpg?aio=w-745;h-419;crop;'},
     {'id': 22,
      'propertyName': 'Vrb Akash',
      'description': '3 Bedroom 1260 Sq.Ft. Apartment in Velachery Chennai',
      'price': '₹ 1.13 Cr.',
      'City': 'Chennai',
      'Locality': 'Velachery',
      'Price': '1.13 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638448240892707740-2902240521292129.png?aio=w-745;h-419;crop;'},
     {'id': 23,
      'propertyName': 'TVS Emerald Luxor',
      'description': '4 Bedroom 2655 Sq.Ft. Apartment in Anna Nagar West Chennai',
      'price': '₹ 5.59 Cr.',
      'City': 'Chennai',
      'Locality': 'Anna Nagar West',
      'Price': '5.59 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638439579509383839-1902240445504550.jpg?aio=w-745;h-419;crop;'},
     {'id': 24,
      'propertyName': 'Appaswamy Wingfield',
      'description': '3 Bedroom 2003 Sq.Ft. Apartment in Kottivakkam Chennai',
      'price': '₹ 2.03 Cr.',
      'City': 'Chennai',
      'Locality': 'Kottivakkam',
      'Price': '2.03 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638434523500509448-1302240819101910.jpg?aio=w-745;h-419;crop;'},
     {'id': 25,
      'propertyName': 'Harmonys Bluemoon',
      'description': '2 Bedroom 990 Sq.Ft. Apartment in Pallavaram Chennai',
      'price': '₹ 70 L',
      'City': 'Chennai',
      'Locality': 'Pallavaram',
      'Price': '70 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638440138886137271-200224081808188.jpeg?aio=w-745;h-419;crop;'},
     {'id': 26,
      'propertyName': 'Palikaranai',
      'description': '3 Bedroom 1137 Sq.Ft. Apartment in Pallikaranai Chennai',
      'price': '₹ 87.02 L',
      'City': 'Chennai',
      'Locality': 'Pallikaranai',
      'Price': '87.02 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638524207049525932-2705240331443144.jpg?aio=w-745;h-419;crop;'},
     {'id': 27,
      'propertyName': 'Dream Castle Urapakkam',
      'description': '4 Bedroom 2100 Sq.Ft. Villa in Urapakkam Chennai',
      'price': '₹ 88.2 L',
      'City': 'Chennai',
      'Locality': 'Urapakkam',
      'Price': '88.2 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638395686287320071-3012230930283028.jpeg?aio=w-745;h-419;crop;'},
     {'id': 28,
      'propertyName': 'Green Park',
      'description': '4 Bedroom 1701 Sq.Ft. Villa in Urapakkam Chennai',
      'price': '₹ 75.7 L',
      'City': 'Chennai',
      'Locality': 'Urapakkam',
      'Price': '75.7 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638416982075513560-240124010327327.jpeg?aio=w-745;h-419;crop;'},
     {'id': 29,
      'propertyName': 'Valasaravakkam Flats ',
      'description': '2 Bedroom 945 Sq.Ft. Apartment in Valasaravakkam Chennai',
      'price': '₹ 80.5 L',
      'City': 'Chennai',
      'Locality': 'Valasaravakkam',
      'Price': '80.5 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638449861218100224-020324022201221.jpg?aio=w-745;h-419;crop;'},
     {'id': 30,
      'propertyName': 'Advaita Brindavan',
      'description': '3 Bedroom 1545 Sq.Ft. Apartment in Old Washermanpet Chennai',
      'price': '₹ 1.05 Cr.',
      'City': 'Chennai',
      'Locality': 'Old Washermanpet',
      'Price': '1.05 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638486301481352214-1304240635483548.jpg?aio=w-745;h-419;crop;'},
     {'id': 31,
      'propertyName': 'Mappedu',
      'description': 'Commercial Land 2400 Sq.Ft. in Mappedu Chennai',
      'price': '₹ 32.4 L',
      'City': 'Chennai',
      'Locality': 'Mappedu',
      'Price': '32.4 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638425521228809178-0302241015221522.jpeg?aio=w-745;h-419;crop;'},
     {'id': 32,
      'propertyName': 'Guduvancherry Own Project',
      'description': '600 Sq.Ft. Plot in Guduvanchery Chennai',
      'price': '₹ 15 L',
      'City': 'Chennai',
      'Locality': 'Guduvanchery',
      'Price': '15 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638502556843279632-02052402080484.jpg?aio=w-745;h-419;crop;'},
     {'id': 33,
      'propertyName': 'Casagrand Cloud9',
      'description': '3 Bedroom 2002 Sq.Ft. Apartment in Sholinganallur Chennai',
      'price': '₹ 1.25 Cr.',
      'City': 'Chennai',
      'Locality': 'Sholinganallur',
      'Price': '1.25 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638434492654111195-1302240727452745.jpg?aio=w-745;h-419;crop;'},
     {'id': 34,
      'propertyName': 'Casagrand Supremus',
      'description': '3 Bedroom 1372 Sq.Ft. Apartment in Thalambur Chennai',
      'price': '₹ 79 L',
      'City': 'Chennai',
      'Locality': 'Thalambur',
      'Price': '79 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638426610033898951-040224043003303.jpeg?aio=w-745;h-419;crop;'},
     {'id': 35,
      'propertyName': 'Ssg Plots',
      'description': '3 Bedroom 1320 Sq.Ft. Villa in Pudupakkam Chennai',
      'price': '₹ 85 L',
      'City': 'Chennai',
      'Locality': 'Pudupakkam',
      'Price': '85 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638504165308509144-0405241048504850.jpg?aio=w-745;h-419;crop;'},
     {'id': 36,
      'propertyName': 'Chaitanya Nest',
      'description': '3 Bedroom 1484 Sq.Ft. Apartment in Alwarpet Chennai',
      'price': '₹ 2.2 Cr.',
      'City': 'Chennai',
      'Locality': 'Alwarpet',
      'Price': '2.2 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638399707699747392-0401240112491249.jpg?aio=w-745;h-419;crop;'},
     {'id': 37,
      'propertyName': 'Resale ',
      'description': '3200 Sq.Ft. Plot in Tambaram East Chennai',
      'price': '₹ 1.44 Cr.',
      'City': 'Chennai',
      'Locality': 'Tambaram East',
      'Price': '1.44 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638391290771714403-2512230724372437.jpg?aio=w-745;h-419;crop;'},
     {'id': 38,
      'propertyName': 'Godrej Sunrise Estate',
      'description': '2000 Sq.Ft. Plot in Oragadam Chennai',
      'price': '₹ 54.89 L',
      'City': 'Chennai',
      'Locality': 'Oragadam',
      'Price': '54.89 L',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/4fe5c899-b1c7-4b32-9344-ad7b4beb786d.jpg?aio=w-745;h-419;crop;'},
     {'id': 39,
      'propertyName': 'TVS Emerald Luxor',
      'description': '3 Bedroom 2176 Sq.Ft. Apartment in Anna Nagar West Chennai',
      'price': '₹ 4.21 Cr.',
      'City': 'Chennai',
      'Locality': 'Anna Nagar West',
      'Price': '4.21 Cr.',
      'propertyPhoto': 'https://img.squareyards.com/secondaryPortal/638417948466019683-250124035406546.jpg?aio=w-745;h-419;crop;'}
  ]

};


export default properties;
